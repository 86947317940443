<template>
  <div>
    <van-search v-model="searchKey" show-action placeholder="请输入搜索关键词" @search="handleSearch">
      <template #left>
        <van-icon name="arrow-left" size="20" @click="toUcenter" />
      </template>
      <template #action>
        <div @click="handleSearch">搜索</div>
      </template>
    </van-search>
    <van-tabs v-model="active" sticky @click="handleTagsClick">
      <van-tab title="全部">
        <order-list ref="order_0" :order-status="999" :app-id="appId" />
      </van-tab>
      <van-tab title="未支付">
        <order-list ref="order_1" :order-status="0" :app-id="appId" />
      </van-tab>
      <van-tab title="已支付">
        <order-list ref="order_2" :order-status="1" :app-id="appId" />
      </van-tab>
      <van-tab title="已取消">
        <order-list ref="order_3" :order-status="-1" :app-id="appId" />
      </van-tab>
    </van-tabs>

  </div>
</template>
<script>
import OrderList from './components/OrderList'
export default {
  components: {
    OrderList
  },
  data() {
    return {
      searchKey: '',
      active: 0,
      appId: '',
      loading: false,
      finished: false,
      dataList: [],
      maxOrderId: '',
      curTabIdx: 0
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {},
  methods: {
    handleTagsClick(idx, title) {
      this.curTabIdx = idx
    },
    handleSearch() {
      this.$refs['order_' + this.curTabIdx].doSearch(this.searchKey)
    },
    // initDataList() {
    //   orderApi.list({ maxId: this.maxOrderId, orderStatus: 999 }).then(res => {
    //     if (res.succ) {
    //       let items = res.data.items
    //       if (items.length === 0) {
    //         this.finished = true
    //       } else {
    //         for (let i = 0; i < items.length; i++) {
    //           this.dataList.push(items[i])
    //         }
    //         this.loading = false
    //         this.maxOrderId = items[items.length - 1].orderId
    //       }
    //     }
    //   })
    // },
    // // 清空列表，重新加载
    // reloadDataList() {
    //   this.maxOrderId = ''
    //   this.finished = false
    //   this.dataList = []
    //   this.initDataList()
    // },
    // handleCancelOrder(item) {
    //   this.$dialog
    //     .confirm({
    //       title: '取消订单',
    //       message: '确定取消此订单吗？'
    //     })
    //     .then(() => {
    //       orderApi.cancel(item.orderNum).then(res => {
    //         if (res.succ) {
    //           this.reloadDataList()
    //         }
    //       })
    //     })
    //     .catch(() => {
    //       // on cancel
    //     })
    // },
    // toDetail(item) {
    //   this.$router.push(`/myActivityOrderDetail/${this.appId}/${item.orderNum}`)
    // },
    toUcenter() {
      this.$router.push(`/${this.appId}/ucenter`)
    }
  }
}
</script>
<style lang="less" scoped>
.van-icon-arrow-left {
  margin-right: 10px;
}
</style>
