<template>
  <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="initDataList">
    <van-card v-for="item in dataList" :key="item.id" :desc="item.activityDescn" :title="item.activityTitle" :thumb="item.activityCover">
      <template #price-top>
        <div class="order-num">{{ item.orderNum }}</div>
      </template>
      <template #price>
        <div>{{ (item.orderStatus === 1?item.consumeMoney:item.needPayMoney) | formatMoney }}</div>
      </template>
      <template #tags>
        <van-tag plain type="danger">{{ item.orderStatus | formatOrderStatus }}</van-tag>
      </template>
      <template v-if="item.orderStatus === 0" #footer>
        <van-button size="mini" type="primary" @click="toDetail(item)">继续支付</van-button>
        <van-button size="mini" type="danger" @click="handleCancelOrder(item)">取消支付</van-button>
      </template>
      <template v-else #footer>
        <van-button size="mini" type="primary" @click="toDetail(item)">订单详情</van-button>
      </template>
    </van-card>
  </van-list>
</template>
<script>
import orderApi from '@/api/order'
export default {
  props: {
    orderStatus: {
      type: Number,
      default: 999
    },
    appId: {
      type: String,
      default: ''
    }
    // dataList: {
    //   type: Array,
    //   default: null
    // }
  },
  data() {
    return {
      loading: false,
      finished: false,
      dataList: [],
      maxOrderId: '',
      searckKey: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    doSearch(keyword) {
      this.searckKey = keyword
      this.reloadDataList()
    },
    initDataList() {
      orderApi.list({ maxId: this.maxOrderId, orderStatus: this.orderStatus, searchKey: this.searckKey }).then(res => {
        if (res.succ) {
          let items = res.data.items
          if (items.length === 0) {
            this.finished = true
          } else {
            for (let i = 0; i < items.length; i++) {
              this.dataList.push(items[i])
            }
            this.loading = false
            this.maxOrderId = items[items.length - 1].orderId
          }
        }
      })
    },
    handleCancelOrder(item) {
      this.$dialog
        .confirm({
          title: '取消订单',
          message: '确定取消此订单吗？'
        })
        .then(() => {
          orderApi.cancel(item.orderNum).then(res => {
            if (res.succ) {
              this.reloadDataList()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    // 清空列表
    clearDataList() {
      this.maxOrderId = ''
      this.finished = false
      this.dataList = []
    },
    // 清空列表，重新加载
    reloadDataList() {
      this.clearDataList()
      this.initDataList()
    },
    toDetail(item) {
      this.$router.push(`/${this.appId}/ucenter/order/detail/${item.orderNum}`)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
